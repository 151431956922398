import React from 'react'
import styled from 'styled-components'

// Components
import ContainerContactUs from '../../components/ContactUs/ContactContainer/ContainerContactUs'

const Contact:React.FC = () => {

    return (
        <ContactUsContainer>
            <ContainerContactUs />
        </ContactUsContainer>
    )
}

const ContactUsContainer = styled.section`
    width: 100%;
    height: 1422px;
    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1167px;
    }
`

export default Contact
