import React from 'react'
import styled from 'styled-components';

// Icons
import RightGray from '../../../assets/icons/icon-arrow-right-gray.svg';

const HomeNews:React.FC = () => {

    const NewsContetns:{title: string, url: string}[] =[
        {"title": "요양원·주야간보호 알림장 케어노트, '오늘케어'로 서비스 명칭 변경 및 플랫폼 리뉴얼", "url" : "http://www.discoverynews.kr/news/articleView.html?idxno=685952"},
        {"title": "시니어 종합 기업 메가홀딩스, 라이트하우스와 MOU 체결", "url" : "https://www.dailysecu.com/news/articleView.html?idxno=129281"},
        {"title": "비대면 요양 시설 알림장 '오늘케어', 보호자 알림장 누적 15,000건 돌파", "url" : "https://www.thefirstmedia.net/news/articleView.html?idxno=84846"},
        {"title": "비대면 요양 시설 알림장 '오늘케어', '케어엔젤'과 시니어 콘텐츠 ", "url" : "http://www.idsn.co.kr/news/articleView.html?idxno=58400"},
        {"title": "라이트하우스, 비대면 요양 시설 무료 알림장 ‘케어노트’ 정식 론칭", "url" : "https://platum.kr/archives/170213"},
        {"title": "안양시사회복지협-라이트하우스, 지역사회 공헌 등 업무협약", "url" : "http://www.kihoilbo.co.kr/news/articleView.html?idxno=930290"},        
    ];

    return (
        <LHNews>
            <NewBox>
                <LHNewsTitle>
                    라이트하우스 소식
                </LHNewsTitle>
                {NewsContetns.map(({title, url}, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <LHNewsContents onClick={()=> window.open(url)}>
                                <div style={{width:"100%" ,display: 'flex', justifyContent:'space-between', alignItems:'center'}}>
                                    <LHNewsSubTitle>
                                        {title}
                                    </LHNewsSubTitle>
                                    <RightGrayArrow src={RightGray} alt="leftGrayArrow" />
                                </div>
                                    <LHNewsUnderLine />
                            </LHNewsContents>
                        </React.Fragment>
                    );
                })}
            </NewBox>
        </LHNews>     
    )
}

const LHNews = styled.section`
    width: 100%;
    height: 1314px;
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4017px;
    left: 0;
    background: #F6F4F2;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1096px;
        position: absolute;
        top: 3246px;
        left: 0px;
    }
`

const NewBox = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    
    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1096px;
    }
`

const LHNewsTitle = styled.h1`
    width: 492px;
    height: 50px;
    font-weight: bold;
    font-size: 52px;
    line-height: 55px;
    color: #8C8579;
    margin-bottom: 100px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 205px;
        height: 30px;
        font-size: 24px;
        margin: 100px 154px 60px 16px;
    }
`

const LHNewsContents= styled.div`
    width: 1200px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    color: #191F29;
    transition: 1s;
    word-break:keep-all;
    cursor: pointer;
    margin-bottom: 50px;
    
    :hover {
        transform: scale(1.0050);        
    }

    :nth-child(3) {
        margin-top: 50px;
    }

    :nth-child(4) {
        margin-top: 50px;
    }

    :nth-child(5) {
        margin-top: 50px;
    }

    :nth-child(6) {
        margin-top: 50px;
    }

    :nth-child(7) {
        margin-top: 50px;
    }
    
        /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 90%;
        height: 100px;
        font-size: 16px;
        line-height: 30px;
        margin-top: 0px;
        margin-bottom: 40px;
        margin-left: 16px;
        
        :hover {
        transform: scale(1.0050);        
        }
        :nth-child(2) {
            margin-top: 0px;
        }
        :nth-child(3) {
            margin-top: 0px;
        }
        :nth-child(4) {
            margin-top: 0px;
        }
        :nth-child(5) {
            margin-top: 0px;
        }
        :nth-child(6) {
            margin-top: 0px;
        }
        :nth-child(7) {
            margin-top: 0px;
            margin-bottom: 100px;
        }
    }
    
`

const LHNewsSubTitle = styled.span`
    width: 781px;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 90%;
        height: 60px;
        font-size: 16px;
        line-height: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 40px;
        :hover {
        transform: scale(1.0050);        
        }
        :nth-child(2) {
            top: 190px;
        }
        :nth-child(3) {
            top: 310px;
        }
        :nth-child(4) {
            top: 459px;
        }
        :nth-child(5) {
            top: 608px;
        }
        :nth-child(6) {
            top: 757px;
        }
        :nth-child(7) {
            top: 906px;
        }
    }
`

const LHNewsUnderLine = styled.div`
    width: 1200px;
    height: 1px;
    border-bottom: 1px solid #E6E6E6;
    cursor: none;
    margin-top: 50px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1px;
        margin-top: 0px;
    }
`

const RightGrayArrow = styled.img`
    width: 40px;
    height: 40px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 24px;
        height: 24px;
        margin-bottom: 40px;
    }
`

export default HomeNews
