import React, { useState } from 'react'
import styled from 'styled-components'

// Components
import Modal from '../Modal/Modal';

// Images
import ContactImg from '../../../assets/img/desktop/banner/contact.png';
import ContactImgM from '../../../assets/img/mobie/banner/unsplash_3no88nSvK88.png';

// Icons
import Lightlogo from '../../../assets/icons/Lightbulb.svg'

const ContainerContactUs:React.FC = () => {

    const [modalState, setModalState] = useState(false);

    const openModal = () => {
        setModalState(true)
    }

    const closeModal = () => {
        setModalState(false);
    }

    return (
        <>
            <Container>
                <BackgroundContainer background={ContactImg}>
                    <ContactContainer display={modalState ? "none" : "flex"} >
                        <ContactTitle>
                            CONTACT
                        </ContactTitle>
                        <KorAdress>
                            경기도 성남시 분당구 황새울로 360번길 21, 8층
                        </KorAdress>
                        <EngAdress>
                            Lighthouse inc., 21, Hwangsaeul-ro 360beon-gil,<br/>
                            Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea
                        </EngAdress>
                        <EngAdressM>
                            Lighthouse inc.<br/>
                            21, Hwangsaeul-ro 360beon-gil,<br/>
                            Bundang-gu, Seongnam-si, Gyeonggi-do,<br/>
                            Republic of Korea
                        </EngAdressM>
                        <Tel>
                            Tel 031.604.0090
                        </Tel>
                        <Email>
                            E-mail support@lighthouse.love
                        </Email>
                        <ContentsBox1>
                            <LightLogo src={Lightlogo} alt="LightLogo" />
                            <ContentsBox2>
                                <Content01>우리의 목표, 서비스, 업무에 관심이 있으신가요? 주저하지 말고 연락주세요!</Content01>
                                <Content02>우리의 사업에 함께하고자 하시는 분은 투자자료 요청을 주시기 바랍니다. </Content02>
                                <Content03>비즈니스 모델과 상세한 서비스 설명이 포함된 자료를 보내드립니다.		</Content03>
                            </ContentsBox2>
                        </ContentsBox1>
                        <ContentsBox3>
                            <Blog onClick={() => window.open('https://blog.naver.com/lighthouse0827')}>BLOG</Blog>
                            <ContactUs onClick={openModal}>CONTACT US</ContactUs>
                        </ContentsBox3> 
                    </ContactContainer>
                    <Modal state={modalState} closeModal={closeModal} />
                </BackgroundContainer>

                <ContactContainerM display={modalState ? "none" : "flex"} >
                        <ContactTitle>
                            CONTACT
                        </ContactTitle>
                        <KorAdress>
                            경기도 성남시 분당구 황새울로 360번길 21, 8층
                        </KorAdress>
                        <EngAdress>
                            Lighthouse inc., 21, Hwangsaeul-ro 360beon-gil,<br/>
                            Bundang-gu, Seongnam-si, Gyeonggi-do, Republic of Korea
                        </EngAdress>
                        <EngAdressM>
                            Lighthouse inc.<br/>
                            21, Hwangsaeul-ro 360beon-gil,<br/>
                            Bundang-gu, Seongnam-si, Gyeonggi-do,<br/>
                            Republic of Korea
                        </EngAdressM>
                        <Tel>
                            Tel 031.604.0090
                        </Tel>
                        <Email>
                            E-mail support@lighthouse.love
                        </Email>
                        <ContentsBox1>
                            <LightLogo src={Lightlogo} alt="LightLogo" />
                            <ContentsBox2>
                                <Content01>우리의 목표, 서비스, 업무에 관심이 있으신가요? 주저하지 말고 연락주세요!</Content01>
                                <Content02>우리의 사업에 함께하고자 하시는 분은 투자자료 요청을 주시기 바랍니다. </Content02>
                                <Content03>비즈니스 모델과 상세한 서비스 설명이 포함된 자료를 보내드립니다.		</Content03>
                            </ContentsBox2>
                        </ContentsBox1>

                        {/* mobile */}
                        
                        <Content01M>
                            우리의 목표, 서비스, 업무에 관심이 있으신가요?<br/>
                            주저하지 말고 연락주세요!
                        </Content01M>
                        <Content02M>
                            우리의 사업에 함께하고자 하시는 분은 투자자료<br/>
                            요청을 주시기 바랍니다. 비즈니스 모델과 상세한<br/>
                            서비스 설명이 포함된 자료를 보내드립니다. 
                        </Content02M>

                        <ContentsBox3>
                            <Blog onClick={() => window.open('https://blog.naver.com/lighthouse0827')}>BLOG</Blog>
                            <ContactUs onClick={openModal}>CONTACT US</ContactUs>
                        </ContentsBox3> 
                    </ContactContainerM>
            </Container>
        </>
    )
}

interface IContactStyleProps {
    background?: string;
    display?: string;
  }

const Container = styled.div`
    width: 100%;
    height: 1422px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1167px;
        display: flex;
        justify-content: flex-start;
        /* align-items: end; */
    }
`

const BackgroundContainer = styled.div<IContactStyleProps>`
    width: 100%;
    height: 100%;
    background-image:${props => `url(${props.background})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 410px;
        display: flex;
        background-image:url(${ContactImgM});
        z-index: 6;
        top: 0;
    }
`

const ContactContainer = styled.div<IContactStyleProps>`
    width: 786px;
    height: 625px;
    display: ${props => `${props.display}`};
    flex-direction: column;
    justify-content: center;
    align-items: start;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50px;
    z-index: 5;
    margin-top: 225px;
    margin-left: 361px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 757px;
        display: none;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        background: #ffffff;
        margin: 0px;
    }
`

const ContactContainerM = styled.div<IContactStyleProps>`
    width: 786px;
    height: 625px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50px;
    z-index: 5;
    margin-top: 225px;
    margin-left: 361px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 757px;
        display: ${props => `${props.display}`};
        justify-content: center;
        align-items: center;
        border-radius: 0;
        background: #ffffff;
        /* margin-top: 410px;
        margin-bottom: 0px; */
        margin: 0px;
    }
`

const ContactTitle = styled.h1`
    width: 250px;
    height: 60px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 52px;
    line-height: 60px;
    margin-bottom: 80px;
    margin-left: 60px;
    margin-top: 60px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 203px;
        height: 37px;
        display: flex;
        font-size: 40px;
        margin: 42px 156px 20px 16px;
    }
`

const KorAdress = styled.span`
    width: 381px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 7px;
    margin-left: 60px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 309px;
        height: 24px;
        display: flex;
        font-size: 16px;
        margin-bottom: 10px;
        margin-left: 16px;
        margin-right: 50px;
    }
`
const EngAdress = styled.span`
    width: 543px;
    height: 62px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    margin-bottom: 30px;
    margin-left: 60px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const EngAdressM = styled.span`
    width: 543px;
    height: 62px;
    display: none;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 303px;
        height: 96px;
        display: flex;
        font-size: 16px;
        line-height: 24px;
        color: #6B7684;
        margin-bottom: 40px;
        margin-left: 16px;
        margin-right: 56px;
    }
`
const Tel = styled.span`
    width: 184px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-left: 60px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 141px;
        height: 24px;
        display: flex;
        font-size: 16px;
        margin-bottom: 10px;
        margin-left: 16px;
        margin-right: 218px;
        font-weight: 500;
    }
`

const Email = styled.span`
    width: 289px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
    margin-left: 60px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 236px;
        height: 24px;
        display: flex;
        font-size: 16px;
        margin-bottom: 100px;
        margin-left: 16px;
        margin-right: 123px;
        font-weight: 500;
    }
`
const ContentsBox1 = styled.div`
    display: flex;
    margin-left: 60px;
    margin-bottom: 49px;

    ${({theme}) => theme.device.mobile} {
        margin: 0px;
    }
`

const ContentsBox2 = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
`

const LightLogo = styled.img`
    width: 32px;
    height: 32px;
    
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const Content01 = styled.span`
    width: 467px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const Content01M = styled.span`
    display: none;
    line-height: 24px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 312px;
        height: 48px;
        display: flex;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 10px;
        margin-left: 16px;
        margin-right: 47px;
    }
`

const Content02 = styled.span`
    width: 449px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const Content02M = styled.span`
    display: none;
    line-height: 24px;

    ${({theme}) => theme.device.mobile} {
        width: 305px;
        height: 78px;
        display: flex;
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 73px;
        margin-left: 16px;
        margin-right: 54px;
    }
`

const Content03 = styled.span`
    width: 425px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const ContentsBox3 = styled.div`
    display: flex;
    margin-left: 534px;
    margin-right: 72px;

    ${({theme}) => theme.device.mobile} {
        margin-bottom: 73px;
        margin-left: 77px;
    }
`

const Blog = styled.div`
    width: 43px;
    height: 13px;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 16px;
    line-height: 13px;
    color: #191F29;
    cursor: pointer;
    margin-top: 10px;
    padding-bottom: 10px;

    :after {    
        width: 0;
        height: 1px;
        display: block;
        bottom: 0;
        content: "";
        left: 50%;
        background: none repeat scroll 0 0 transparent;
        position: absolute;
        background: #000000;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }

        :hover:after {
            width:100%;
            left:0
        }

    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const ContactUs = styled.div`
    width: 102px;
    height: 13px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 13px;
    color: #191F29;
    cursor: pointer;
    margin-left: 35px;
    position: relative;
    margin-top: 10px;
    padding-bottom: 10px;

    :after {    
        width: 0;
        height: 1px;
        display: block;
        bottom: 0;
        content: "";
        left: 50%;
        background: none repeat scroll 0 0 transparent;
        position: absolute;
        background: #000000;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }
        :hover:after {
            width:100%;
            left:0
        }

    ${({theme}) => theme.device.mobile} {
        width: 222px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #191F29;
        border-radius: 10px;
        margin: 0px;
        padding: 0px;

        :hover {
        background: #8C8579;
        color: #ffffff;
        border-radius: 15px;
        border: none;
        }
        ::after {
            display: none;
        }

        :active {
            background: #696358;
            color: #ffffff;
            border-radius: 15px;
            border: none;
        }
    }
`

export default ContainerContactUs
