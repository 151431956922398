import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import footerLogo from "../../assets/icons/footer-logo.svg";
import footerLogo2 from "../../assets/icons/footer2-logo.svg";
import footerLogoM from "../../assets/icons/footerM-logo.svg";

const Footer: React.FC = () => {
  let navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <FooterContainer>
        <FooterBoxContainer>
          <FooterBox>
            <NavBox>
              <NavItems onClick={handleHomeClick} width={53}>
                HOME
              </NavItems>
              <Bar />
              <NavItems
                onClick={() => window.open("https://www.carehouselove.com/")}
                width={78}
              >
                케어하우스
              </NavItems>
              <Bar />
              <NavItems
                onClick={() => window.open("https://www.todaycare.kr/")}
                width={63}
              >
                오늘케어
              </NavItems>
              <Bar />
              <NavItems
                onClick={() =>
                  window.open("https://blog.naver.com/lighthouse0827")
                }
                width={48}
              >
                BLOG
              </NavItems>
            </NavBox>
            <AdressBox>
              <AdressItems style={{ width: "95px" }}>
                (주)라이트하우스
              </AdressItems>
              <Bar />
              <AdressItems style={{ width: "267px" }}>
                경기도 성남시 분당구 황새울로 360번길 21, 8층
              </AdressItems>
              <Bar />
              <AdressItems style={{ width: "95px" }}>031-604-0090</AdressItems>
            </AdressBox>
            <BuisnessBox>
              <BuisnessItems style={{ width: "182px" }}>
                사업자등록번호 810-88-01419
              </BuisnessItems>
              <Bar />
              <BuisnessItems style={{ width: "212px" }}>
                고객문의 support@lighthouse.love
              </BuisnessItems>
            </BuisnessBox>
            <CopyLightBox>
              Copyright © 2021 Lighthouse Inc. All rights reserved.
            </CopyLightBox>
          </FooterBox>
          <LogoBax>
            <FooterLogo src={footerLogo} alt="FooterLogo" />
            <FooterLogo2 src={footerLogo2} alt="FooterLogo2" />
          </LogoBax>
        </FooterBoxContainer>
      </FooterContainer>

      {/* mobile */}
      <FooterContainerM>
        <FooterBoxM>
          <FooterLogoM src={footerLogo} alt="FooterLogo" />
          <NavBoxM>
            <NavItemsM onClick={handleHomeClick} width={48}>
              HOME
            </NavItemsM>
            <Bar />
            <NavItemsM
              onClick={() => window.open("https://www.carehouselove.com/")}
              width={70}
            >
              케어하우스
            </NavItemsM>
            <Bar />
            <NavItemsM
              onClick={() =>
                window.open("https://www.carehouselove.com/todaycare/")
              }
              width={56}
            >
              오늘케어
            </NavItemsM>
            <Bar />
            <NavItemsM
              onClick={() =>
                window.open("https://blog.naver.com/lighthouse0827")
              }
              width={43}
            >
              BLOG
            </NavItemsM>
          </NavBoxM>
          <AdressItems01 style={{ width: "105px" }}>
            (주)라이트하우스
          </AdressItems01>
          <AdressItems02 style={{ width: "287px" }}>
            경기도 성남시 분당구 황새울로 360번길 21, 8층
          </AdressItems02>
          <AdressItems03 style={{ width: "287px" }}>
            031-604-0090 | 사업자등록번호 810-88-01419
          </AdressItems03>
          <BuisnessItems02 style={{ width: "212px" }}>
            고객문의 support@lighthouse.love
          </BuisnessItems02>
          <CopyLightBoxM>
            Copyright © 2021 Lighthouse Inc. All rights reserved.
          </CopyLightBoxM>
          <FooterLogoM2 src={footerLogoM} alt="footerLogo2Mobile" />
        </FooterBoxM>
      </FooterContainerM>
    </>
  );
};

interface IFooterStyleProps {
  width?: number;
}

const FooterContainer = styled.section`
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 0px;
  background: #8c8579;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const FooterBoxContainer = styled.div`
  width: 1200px;
  height: 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const FooterContainerM = styled.section`
  display: none;
  background: #8c8579;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 597px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
`;

const FooterBox = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
`;

const FooterBoxM = styled.div`
  display: none;
  flex-direction: column;
  margin-top: 100px;
  margin-left: 18.9%;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
`;

const NavBox = styled.div`
  width: 500px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-bottom: 44px;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const NavBoxM = styled.nav`
  display: none;
  padding: 0px;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 281px;
    height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
`;

const NavItems = styled.div<IFooterStyleProps>`
  width: ${(props) => `${props.width}`}px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  position: relative;
  color: #ffffff;
  cursor: pointer;

  :after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: #ffffff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  :hover:after {
    width: 100%;
    left: 0;
  }

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const NavItemsM = styled.div<IFooterStyleProps>`
  width: ${(props) => `${props.width}`}px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  position: relative;

  ::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: #ffffff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  :hover:after {
    width: 100%;
    left: 0;
  }

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
  }
`;

const Bar = styled.div`
  width: 1px;
  height: 12px;
  margin: 0 10px;
  background: #ffffff;
`;

const AdressBox = styled.div`
  width: 499px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  margin-bottom: 7px;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const AdressItems = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  :nth-child(3) {
    border-right: none;
  }
  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;
const AdressItems01 = styled.div`
  display: none;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 95px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 10px;
  }
`;

const AdressItems02 = styled.div`
  display: none;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 267px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 10px;
  }
`;

const AdressItems03 = styled.div`
  display: none;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 96px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 10px;
  }
`;

const BuisnessBox = styled.div`
  width: 415px;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-bottom: 7px;
`;

const BuisnessItems = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  :nth-child(2) {
    border-right: none;
  }
`;

const BuisnessItems02 = styled.div`
  display: none;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 212px;
    height: 20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-bottom: 10px;
  }
`;

const CopyLightBox = styled.div`
  width: 290px;
  height: 18px;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const CopyLightBoxM = styled.div`
  display: none;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: flex;
    width: 290px;
    height: 18px;
  }
`;

const LogoBax = styled.div`
  display: flex;
  align-items: center;
`;

const FooterLogo = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 30px;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const FooterLogo2 = styled.img`
  width: 80px;
  height: 80px;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;

const FooterLogoM = styled.img`
  display: none;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 75px;
    height: 75px;
    display: flex;
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const FooterLogoM2 = styled.img`
  display: none;

  /* mobile */
  ${({ theme }) => theme.device.mobile} {
    width: 140px;
    height: 40.89px;
    display: flex;
    margin-top: 60px;
    margin-bottom: 100px;
  }
`;

export default Footer;
