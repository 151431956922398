import React from 'react'
import styled from 'styled-components'


// Image
import TodayCareImg from '../../../assets/img/desktop/home/오늘케어.png';
import TodayCareHover from '../../../assets/img/desktop/home/오늘케어호버이미지.png';
import TodayCareImgM from '../../../assets/img/mobie/home/오늘케어_모바일.png';
import TodayCareHoverM from '../../../assets/img/mobie/home/오늘케어_호버 이미지(모바일).png';
import CareHouseImg from '../../../assets/img/desktop/home/케어하우스.png'
import CareHouseHover from '../../../assets/img/desktop/home/케어하우스_호버 이미지.png';
import CareHouseImgM from '../../../assets/img/mobie/home/케어하우스_모바일.png'
import CareHouseHoverM from '../../../assets/img/mobie/home/케어하우스_호버 이미지(모바일).png';
import Default from '../../../assets/Transparent.gif';

// Icon
import rightBlack from '../../../assets/icons/icon-arrow-right-black.svg';
import rightBlackM from '../../../assets/icons/icon-arrow-right-blackM.svg';
import rightWhite from '../../../assets/icons/icon-arrow-right-white.svg';
import rightWhiteM from '../../../assets/icons/icon-arrow-right-whiteM.svg';

const HomeService:React.FC = () => {

    return (
        <ServiceContainer>
            <Service id={"service"}>
                <ServiceBox  >
                    <ServiceTitle>
                        SERVICE
                    </ServiceTitle>
                    <ServiceSubTitle>
                        장기요양을 더 효율적으로
                    </ServiceSubTitle>
                    <ServiceContent>
                        우리 사회는 전체인구의 5분의 1이 노인인 초고령 사회로 달려가고 있습니다.<br /> 
                        또한 우리나라 노인세대의 노후대비는 매우 열악합니다.  65세 이상 노인 빈곤률은 약 50%에 달합니다.<br />
                        미래사회를 위해선 시니어 시장이 매우 중요하며 시니어 시장 활성화의 가장 중요한 출발점은 장기요양 입니다. 
                    </ServiceContent>

                    {/* mobile */}
                    <ServiceSubTitleM>
                        장기요양을<br /> 더 효율적으로
                    </ServiceSubTitleM>
                    <ServiceContentM>
                        우리 사회는 전체인구의 5분의 1이 노인인<br/> 초고령 사회로 달려가고 있습니다.<br /> 
                        미래사회를 위해선 시니어 시장이 매우 중요하며<br /> 시니어 시장 활성화의 가장 중요한 출발점은<br /> 장기요양 입니다. 
                    </ServiceContentM>
                </ServiceBox>

                <CardBox>
                    <TodayCareBox onClick={() => window.open('https://www.carehouselove.com/todaycare/')}>
                        <TodayCareTitle>
                            오늘케어
                        </TodayCareTitle>
                        <TodayCareContent>
                            요양 시설을 위한<br />
                            스마트한 간편 알림장 서비스
                        </TodayCareContent>
                        <RightBlackArrow src={Default} alt="RightBlackArrowM" />
                    </TodayCareBox>

                    <CareHouseBox onClick={() => window.open('https://www.carehouselove.com/')}>
                        <CareHouseTitle>
                            케어하우스
                        </CareHouseTitle>
                        <CareHouseContent>
                            수급자와 요양시설을<br />
                            연결하는 매칭 시스템
                        </CareHouseContent>
                        <RightBlackArrow src={Default} alt="RightBlackArrow" />
                    </CareHouseBox>
                </CardBox>
            </Service>
        </ServiceContainer>
    )
}

const ServiceContainer = styled.section`
    width: 100%;
    height: 1012px;
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    top: 1600px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1047px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 1256px;
    }
`

const Service = styled.div`
    height: 1012px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1047px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const ServiceBox = styled.div`
    width: 854px;
    height: 276px;
    display:flex;
    flex-direction:column;
    padding: 0px;
    position: relative;
    margin-bottom: 40px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 350px;
        height: 447px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const ServiceTitle = styled.h1`
    width:298px;
    height: 55px;
    font-weight:700;
    font-size:72px;
    line-height: 55px;
    color: #191F29;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 30px;
        display: flex;
        font-size: 40px;
        line-height: 30px;
        margin-top: 100px;
        margin-bottom: 60px;
    }
`

const ServiceSubTitle = styled.h2`
    width: 360px;
    height: 36px;
    position:relative;
    top: 60px;
    font-weight: 500;
    font-size: 36px;
    line-height:36px;
    color: #191F29;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const ServiceSubTitleM = styled.h2`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 72px;
        display: flex;
        justify-content: flex-start;
        text-align: start;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 20px;
    }
`

const ServiceContent = styled.p`
    width: 854px;
    height: 105px;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    position:relative;
    top: 80px;
    font-weight:500;
    font-size:20px;
    line-height:35px;
    color: #6B7684;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const ServiceContentM = styled.p`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 125px;
        display: flex;
        justify-content: flex-start;
        text-align: start;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #6B7684;
        margin-bottom: 40px;
    }
`

const CardBox = styled.div`
    display:flex;
    align-items: center;
    height: auto;
    
    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 375px;
        height: 640px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;        
    }
`

const TodayCareBox = styled.div`
    width: 580px;
    height: 380px;
    position: relative;
    transition: all .5s;
    background-image:url(${TodayCareImg});
    border-radius: 20px;
    margin-right:40px;
    cursor: pointer;
    &:hover {
        transition: background-image .5s;
        background-image: url(${TodayCareHover});
        color: #ffffff;
        img {
            transition: all .5s;
            background-image: url(${rightWhite});
        }
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 343px;
        height: 210px;
        background:url(${TodayCareImgM});
        cursor: pointer;
        transition: all .5s;
        margin-bottom: 20px;
        margin-right: 0px;
        :hover {
            background: url('${TodayCareHoverM}');
            color: #ffffff;
            transition: all .5s;
            img {
                transition: all .5s;
                background-image: url(${rightWhiteM});
            }
        }
    }
`

const TodayCareTitle = styled.h1`
    width: 125px;
    height: 30px;
    position: relative;
    top: 230px;
    left: 40px;
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 83px;
        height: 30px;
        top: 104px;
        left: 20px;
        font-size: 24px;
        line-height: 30px;
    }
`;

const TodayCareContent = styled.p`
    width: 223px;
    height: 60px;
    position: absolute;
    top: 280px;
    left: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: start;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 178px;
        height: 46px;
        position: absolute;
        top: 144px;
        left: 20px;
        font-size: 16px;
        line-height: 23px;
    }
`

const CareHouseBox = styled.div`
    width: 580px;
    height: 380px;
    position: relative;
    background:url('${CareHouseImg}');
    border-radius: 20px;
    transition: all .5s;
    cursor: pointer;
    :hover {
        transition: background-image 0.5s;
        background-image: url('${CareHouseHover}');
        color: #ffffff;
        img {
            transition: all .5s;
            background-image: url(${rightWhite});
        }
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 343px;
        height: 210px;
        background:url('${CareHouseImgM}');
        cursor: pointer;
        transition: all .5s;
        margin-bottom: 160px;
        :hover {
            background: url('${CareHouseHoverM}');
            color: #ffffff;
            transition: all .5s;
            img {
                background-image: url(${rightWhiteM});
                transition: all .5s;
            }
        }
    }
`

const CareHouseTitle = styled.h1`
    width: 156px;
    height: 30px;
    position: relative;
    top: 230px;
    left: 40px;
    font-weight: bold;
    font-size: 36px;
    line-height: 30px;
    

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 104px;
        height: 30px;
        position: absolute;
        top: 104px;
        left: 20px;
        font-size: 24px;
        line-height: 30px;
    }
`;

const CareHouseContent = styled.p`
    width: 166px;
    height: 60px;
    position: absolute;
    top: 280px;
    left: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: start;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 133px;
        height: 46px;
        position: absolute;
        top: 144px;
        left: 20px;
        font-size: 16px;
        line-height: 23px;
    }
`

const RightBlackArrow = styled.img`
    width: 48px;
    height: 48px;
    position: absolute;
    top: 292px;
    left: 84.9%;
    background-image: url(${rightBlack});

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 25px;
        height: 25px;
        position: absolute;
        top: 165px;
        left: 298px;
        background-image: url(${rightBlackM});
        background-position: center;
        background-repeat: no-repeat;
    }
`

export default HomeService
