import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family:'Pretendard';
        font-weight: 700;
        max-width: 100%;
        height: 100%
    }

    a {
        text-decoration: none;
    }
`

export default GlobalStyles;