import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Close from '../../../assets/icons/icon-close.svg';
import Asterisk from '../../../assets/icons/Asterisk.svg';

interface ModalProps {
    children?: React.ReactNode;
    state: boolean;
    closeModal: any;
}

//todo: any타입을 올바른 타입으로 변경하기

const Modal:React.FC<ModalProps> = (props) => {
    
    // modal props
    const {state, closeModal} = props;

    // contents states
    const [submit, setSubmit] = useState({
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
    });

    // contents value
    const saveName = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSubmit({...submit, name: e.target.value});
    };

    const savePhone = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSubmit({...submit, phone: e.target.value});
    };

    const saveEmail = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSubmit({...submit, email: e.target.value});
    };

    const saveSubject = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSubmit({...submit, subject: e.target.value});
    };

    const saveMessage = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setSubmit({...submit, message: e.target.value});
    };

    // submit function
    const Submit = () => {
        if(submit.name === "") {
            window.alert("이름을 입력해주세요.")
            return
        }
        if(submit.phone === "") {
            window.alert("휴대폰전화 번호를 입력해주세요.")
            return
        }
        if(submit.email === "") {
            window.alert("이메일을 입력해주세요.")
            return
        }
        if(submit.subject === "") {
            window.alert("제목을 입력해주세요.")
            return
        }
        if(submit.message === "") {
            window.alert("메세지를 입력해주세요.")
            return
        }

        sendEmail(submit.name, submit.phone, submit.email, submit.subject, submit.message);

        setSubmit({
            name: "",
            phone: "",
            email: "",
            subject: "",
            message: "",
        })

    }

    // server 통신
    function sendEmail(name:string, phone:string, email:string, subject:string, text:string) {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200) {
                alert('이메일이 잘 접수되었습니다.');
            }
        };
        xhttp.open("POST", "https://www.carehouselove.com/api/email/", true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send(`subject=company page email + &message= + name + | + email + | + phone + | + subject + | + text`);
    }
    // keypress esc
    useEffect(() => {
        const close = (e:{keyCode:number}) => {
            if(e.keyCode === 27) {
                props.closeModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [props])

    return state ?  (
        <ModalContainer>
            <Overlay onClick={event => closeModal(event)} />
            <Background >
                <ModalBox >
                    <CloseIcon src={Close} onClick={event => closeModal(event)} alt="CloseIcon" />
                    <Title width={44} top={120} left={210}>Name</Title>
                    <AsteriskIcon  src={Asterisk} alt="AsteriskIcon" top={120} left={260} />
                    <InputBox onChange={saveName} autoFocus value={submit.name} type="text" placeholder='이름을 입력해 주세요.' top={150} left={210} />

                    <Title width={48} top={120} left={610}>Phone</Title>
                    <AsteriskIcon src={Asterisk} alt="AsteriskIcon"  top={120} left={664}/>
                    <InputBox onChange={savePhone} value={submit.phone} type="tel" placeholder='휴대전화 번호를 입력해 주세요.' top={150} left={610} />

                    <Title width={41} top={242} left={210}>Email</Title>
                    <AsteriskIcon src={Asterisk} alt="AsteriskIcon"  top={243} left={257}/>
                    <InputBox onChange={saveEmail} value={submit.email} type={"email"} placeholder='이메일을 입력해 주세요' top={272} left={210} />

                    <Title width={58} top={242} left={610}>Subject</Title>
                    <AsteriskIcon src={Asterisk} alt="AsteriskIcon"  top={243} left={672}/>
                    <InputBox onChange={saveSubject} value={submit.subject} type={"text"} placeholder='제목을 입력해 주세요.'top={272} left={610} />

                    <Title width={69} top={364} left={210}>Message</Title>
                    <AsteriskIcon src={Asterisk} alt="AsteriskIcon"  top={365} left={279}/>
                    <MessageBox onChange={saveMessage} value={submit.message} placeholder='내용을 입력해 주세요. (최대 300자)' top={394} left={210} />
                    <SendBox onClick={Submit} >보내기</SendBox>

                    {/* mobile */}
                    <CloseIconM src={Close} onClick={event => closeModal(event)} alt="CloseIcon" />
                    <ModalBoxM>
                        <TitleM width={44}>Name</TitleM>
                        <AsteriskIconM  src={Asterisk} alt="AsteriskIcon" />
                    </ModalBoxM>
                    <InputBoxM onChange={saveName} autoFocus value={submit.name} type="text" placeholder='이름을 입력해 주세요.' />

                    <ModalBoxM>
                        <TitleM width={48} >Phone</TitleM>
                        <AsteriskIconM src={Asterisk} alt="AsteriskIcon" />
                    </ModalBoxM>
                    <InputBoxM onChange={savePhone} value={submit.phone} type="tel" placeholder='휴대전화 번호를 입력해 주세요.' />

                    <ModalBoxM>
                        <TitleM width={41} >Email</TitleM>
                        <AsteriskIconM src={Asterisk} alt="AsteriskIcon" />
                    </ModalBoxM>
                    <InputBoxM onChange={saveEmail} value={submit.email} type={"email"} placeholder='이메일을 입력해 주세요' />

                    <ModalBoxM>
                        <TitleM width={58} >Subject</TitleM>
                        <AsteriskIconM src={Asterisk} alt="AsteriskIcon" />
                    </ModalBoxM>
                    <InputBoxM onChange={saveSubject} value={submit.subject} type={"text"} placeholder='제목을 입력해 주세요.' />

                    <ModalBoxM>
                        <TitleM width={69} >Message</TitleM>
                        <AsteriskIconM src={Asterisk} alt="AsteriskIcon"/>
                    </ModalBoxM>
                    <MessageBoxM onChange={saveMessage} value={submit.message} placeholder='내용을 입력해 주세요. (최대 300자)' />
                    <SendBoxM onClick={Submit} >보내기</SendBoxM>
                </ModalBox>
            </Background>
        </ModalContainer>
    ) : (
        <>
        </>
    )
}

interface IModalStyleProps {
    width?: number;
    top?: number | null;
    left?: number | null;
  }

const ModalContainer = styled.section`
    width: 100%;
    height: 100%;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100vw;
        height: 100%;
        display: flex;
    }
`

const Background = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 1167px;
        display: flex;
    }
`

const Overlay = styled.section`
    width: 100%;
    height: 111.2%;
    position: absolute;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        z-index: 7;
        height: 146%;
        display: flex;
        background: rgba(0, 0, 0, 0.6);
    }
`

const ModalBox = styled.div`
    width: 1200px;
    height: 706px;
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    z-index: 2;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 980px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 94px 16px 0px 16px;
        z-index: 10;
    }
`

const CloseIcon = styled.img`
    width: 48px;
    height: 48px;
    position: absolute;
    top: 20px;
    left: 1132px;
    cursor: pointer;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const CloseIconM = styled.img`
    display: none;
    cursor: pointer;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 38px;
        height: 38px;
        display: flex;
        margin: 18px 18px 40.59px 83.5%;
    }
`

const ModalBoxM = styled.div`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 90%;
        height: 24px;
        display: flex;
        margin-bottom: 10px;
    }
`

const AsteriskIcon = styled.img<IModalStyleProps>`
    width: 14px;
    height: 14px;
    position: absolute;
    top: ${props => `${props.top}`}px;
    left:${props => `${props.left}`}px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const AsteriskIconM = styled.img`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 14px;
        height: 14px;
        display: flex;
        margin-left: 4px;
    }
`

const Title = styled.span<IModalStyleProps>`
    width:${props => `${props.width}`}px;
    height: 24px;
    display: flex;
    align-items: center;
    position: absolute;
    top: ${props => `${props.top}`}px;
    left:${props => `${props.left}`}px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
        
    }
`

const TitleM = styled.span<IModalStyleProps>`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: ${props => `${props.width}`}px;
        height: 24px;
        display: flex;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
    }
`

const InputBox = styled.input<IModalStyleProps>`
    width: 380px;
    height: 52px;
    position: absolute;
    top: ${props => `${props.top}`}px;
    left:${props => `${props.left}`}px;
    border: 1px solid #DDE1E6;
    border-radius: 10px;
    
    ::placeholder {
        display: flex;
        align-items:center;
        color: #B0B8C1;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        padding-left: 16px;
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const InputBoxM = styled.input`
    border: 1px solid #DDE1E6;
    border-radius: 10px;
    padding-left: 16px;
    ::placeholder {
        display: flex;
        align-items:center;
        color: #B0B8C1;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
    }
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 90%;
        height: 42px;
        display: flex;
        margin-bottom: 33px;
    }
`

const MessageBox = styled.textarea<IModalStyleProps>`
    width: 380px;
    height: 192px;
    position: absolute;
    display:flex;
    align-items: flex-start;
    top: ${props => `${props.top}`}px;
    left:${props => `${props.left}`}px;
    border: 1px solid #DDE1E6;
    border-radius: 10px;
    resize:none;
    
    ::placeholder {
        color: #B0B8C1;
        height: 24px;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-items:flex-start;
        padding: 16px 0 0 16px;
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const MessageBoxM = styled.textarea`
    display: none;
    border: 1px solid #DDE1E6;
    border-radius: 10px;
    padding: 16px 0 0 16px;
    
    ::placeholder {
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        color: #B0B8C1;
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 90%;
        height: 250px;
        display: flex;
        align-items: flex-start;
        resize:none;
        margin-bottom: 60px;
        overflow: hidden;
    }
`

const SendBox = styled.button`
    width: 222px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 538px;
    left: 768px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #000000;
    padding: 12px 17px;
    cursor: pointer;

    :hover {
        background: #8C8579;
        color: #ffffff;
        border-radius: 15px;
        border: none;
    }
    :active {
        background: #696358;
        color: #ffffff;
        border-radius: 15px;
        border: none;
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const SendBoxM = styled.button`
    display: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 17px;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #000000;
    cursor: pointer;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 222px;
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
    }

    ${({theme}) => theme.device.mobile} {
        width: 222px;
        height: 48px;
        margin-bottom: 60px;

        :hover {
        background: #8C8579;
        color: #ffffff;
        border-radius: 15px;
        border: none;
        }
        :active {
            background: #696358;
            color: #ffffff;
            border-radius: 15px;
            border: none;
        }
    }
`

export default Modal
