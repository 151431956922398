import React,{ useState, useEffect} from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom';
// Icon
import LogoIcon from '../../assets/icons/logo.svg'
import burgerIcon from '../../assets/icons/Burger Menu.svg';
import companyNameIcon from '../../assets/icons/companyName.svg';
import MobileLogoIcon from '../../assets/icons/mobileLogo.svg';
import mobileX from '../../assets/icons/mobileX.svg';
import LogoBlackIcon from '../../assets/icons/logoBlackM.svg';

interface HeadersProps {
    Header?: JSX.Element;
    name?:string;
}

const Header: React.FC<HeadersProps>= ({name}) => {

    let navigate = useNavigate();
    
    const handleHomeClick = () => {
        navigate("/");
        window.scrollTo({top:0, behavior:'smooth'});
    }

    const handleServiceClick = () => {
        if (window.location.pathname === '/') {
            const moveService = document.getElementById("service")
            if (moveService) {
                moveService.scrollIntoView({behavior:'smooth'})
            }
        }
        navigate("/#service");
    }

    const handleHowClick = () => {
        navigate("/how");
        window.scrollTo({top:0, behavior:'smooth'});
    }

    const handleContactClick = () => {
        navigate("/contact");
        window.scrollTo({top:0, behavior:'smooth'});
    }

    const [isOpen, setIsOpen] = useState(false);

    const openHamberger = () => {
        setIsOpen(!isOpen);
    }

    const openHamberGoHome = () => {
        setIsOpen(!isOpen);
        navigate("/");
        window.scrollTo({top:0, behavior:'smooth'});
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    // keypress esc
    useEffect(() => {
        const close = (e: { keyCode: number; }) => {
            if(e.keyCode === 27) {
                setIsOpen(false);
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])

    return (
        <Container id="header">
            <HeaderBox>
                <Logo src={LogoIcon} alt='Logo' onClick={handleHomeClick} />
                <HeaderList>
                            <HeaderItems onClick={handleHomeClick} name={name} >
                                HOME
                            </HeaderItems>
                            <HeaderItems onClick={handleServiceClick} >
                                SERVICE
                            </HeaderItems>
                            <HeaderItems onClick={handleHowClick} >
                                HOW WE WORK
                            </HeaderItems>
                            <HeaderItems onClick={handleContactClick}>
                                CONTACT
                            </HeaderItems>
                    </HeaderList>
            </HeaderBox>

            {/* mobile */}
            <MobileBox>
                <MobileLogo src={isOpen ? LogoBlackIcon : MobileLogoIcon} alt="mobileLogoIcon" onClick={() => {isOpen ? openHamberGoHome() : handleHomeClick()}} />
                <CompanyName src={isOpen ? companyNameIcon : companyNameIcon} alt="LightHouse" />
                <Burger src={isOpen ? mobileX : burgerIcon} alt="burgerIcon" onClick={openHamberger} />
            </MobileBox>

            <HeaderBoxM height={isOpen ? 381 : 0}>
                <HeaderListM>
                    <HeaderItemsM onClick={() => {handleHomeClick(); openHamberger();}} name={name} >
                        HOME
                    </HeaderItemsM>
                    <HeaderItemsM onClick={() => {handleServiceClick(); openHamberger();}} >
                        SERVICE
                    </HeaderItemsM>
                    <HeaderItemsM onClick={() => {handleHowClick(); openHamberger();}} >
                        HOW WE WORK
                    </HeaderItemsM>
                    <HeaderItemsM onClick={() => {handleContactClick(); openHamberger();}}>
                        CONTACT
                    </HeaderItemsM>
                </HeaderListM>
            </HeaderBoxM>
            {isOpen ? <Overlay onClick={event => closeModal()} /> : null }
        </Container>
    )
}

const Container = styled.section`
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 10;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const HeaderBox = styled.header<{height?: number}>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    padding: 0;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const HeaderBoxM = styled.header<{height?: number}>`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: ${props => `${props.height}`}px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top:0;
        left:0;
        overflow-y: hidden;
        transition: all .5s ease-in-out 0s;
        cursor: pointer;
}
`

const Overlay = styled.section`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100vw;
        height: 200vh;
        display: flex;
        position: absolute;
        top: 320px;
        background: none;
    }
`

const HeaderList = styled.ul`
    width: 576px;
    height: 27px;
    display:flex;
    margin: 0;
    list-style:none;
    padding-inline-start: 0px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display:none;
    }
`

const HeaderListM = styled.ul`
    display: none;
    
    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 320px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        padding-top: 59px;
        background: #ffffff
    }
`

const HeaderItems = styled.li<{name?:string}>`
    width: auto;
    height: 27px;
    display: flex;
    align-items:center;
    position: relative;
    font-size: 18px;
    font-weight:normal;
    line-height:27px;
    color: #ffffff;
    margin-right:80px;
    cursor: pointer;
    flex-wrap:wrap;

    &:nth-child(4) {
        margin-right:0px;
    }

    :after {    
        width: 0;
        height: 1px;
        display: block;
        bottom: 0;
        content: "";
        left: 50%;
        background: none repeat scroll 0 0 transparent;
        position: absolute;
        background: #ffffff;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
    }
    
    :hover:after {
        width:100%;
        left:0
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`
const HeaderItemsM = styled.li<{name?:string}>`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 80px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        color: #191F29;
        :hover {
            background: #F0ECE9;
        }
    }
`


const Logo = styled.img`
    width: 50px;
    height: 50px;
    position:absolute;
    left:52px;
    top:15px;
    cursor: pointer;
    z-index: 10;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

// mobile components

const MobileBox = styled.div`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobie} {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 24px;
    }
`

const MobileLogo = styled.img`
        display: none;
        
        /* mobile */
        ${({theme}) => theme.device.mobile} {
            display:flex;
            width: 35px;
            height: 35px;
            z-index: 10;
            cursor: pointer;
    }
`

const CompanyName = styled.img`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display:flex;
        width: 118px;
        height: 8px;
    }
`

const Burger = styled.img`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display:flex;
        width: 24px;
        height: 24px;
        z-index:10;
        cursor: pointer;
    }
`

export default Header