import React from 'react'
import styled from 'styled-components';

const HomeWise:React.FC = () => {
    
    return (
        <WiseSayingContainer>
            <WiseSayingBox>
                <WiseSayingTitle data-aos="fade-up">
                    "Alone we can do little, together we can do much."
                </WiseSayingTitle>
                <WiseSayingKor data-aos="fade-up">
                    혼자서 할 수 있는 일은 작습니다. 함께할 때 우리는 큰 일을 할 수 있습니다.
                </WiseSayingKor>

                <WiseSayingTitleM data-aos="fade-up">
                    "Alone we can do little, together<br/> we can do much."
                </WiseSayingTitleM>
                <WiseSayingKorM data-aos="fade-up">
                    혼자서 할 수 있는 일은 작습니다.<br /> 함께할 때 우리는 큰 일을 할 수 있습니다.
                </WiseSayingKorM>
                <WiseSayingAuthor data-aos="fade-up">
                    -Helen Keller-
                </WiseSayingAuthor>
            </WiseSayingBox>
        </WiseSayingContainer>
    )
}

const WiseSayingContainer = styled.section`
    width: 100%;
    height: 667px;
    position: absolute;
    top: 2612px;
    left: 0;
    background: #EBE8E6;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 416px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2303px;
        left: 0px;
        background: #EBE8E6;
    }
`

const WiseSayingBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 416px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const WiseSayingTitle = styled.h1`
    width: 848px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 36px;
    line-height: 24px;
    color: #191F29;
    margin-top: 260px;
    margin-bottom: 45px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const WiseSayingTitleM = styled.h1`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 275px;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #191F29;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 20px;
    }
`

const WiseSayingKor = styled.span`
    width: 691px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #333D4C;
    margin-bottom: 30px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display:none;
    }
`

const WiseSayingKorM = styled.span`
    display:none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 275px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #333D4C;
        margin-bottom: 10px;
    }
`

const WiseSayingAuthor = styled.span`
    width: 144px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #333D4C;
    margin-bottom: 260px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 111px;
        height: 26px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 100px;
    }
`

export default HomeWise
