import React from 'react'
import styled from 'styled-components'

// Images
import HWWImg from '../../../assets/img/desktop/banner/howwework_banner.png';
import HWWImgM from '../../../assets/img/mobie/banner/howwework_mobile.png';

const HowBanner:React.FC = () => {
    return (
        <Container background={HWWImg}>
          <TextBox>
            <BannerSubText >LIGHTHOUSE inc.</BannerSubText>
            <BannerMainText >HOW WE WORK</BannerMainText>
          </TextBox>
        </Container>
    )
}

interface IHowWeWorkStyleProps {
    background?: string
  }

const Container = styled.div<IHowWeWorkStyleProps>`
  width: 100%;
  height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center ${props => `url(${props.background})`};
  background-size: cover;

  /* mobile */
  ${({theme}) => theme.device.mobile} {
      width: 100%;
      height: 686px;
      background-image:url(${HWWImgM});
      background-size: cover;
  }
`

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 375px;
        height: 686px;
        display: flex;
        justify-content: center;
        align-items: start;
        margin-left: 42px;
    }
`

const BannerSubText = styled.div`
  width: 400px;
  height: 40px;
  display:flex;
  align-items:center;
  font-weight:500;
  font-size: 46px;
  line-height:30px;
  color: #ffffff;
  margin-bottom: 7px;

  /* mobile */
  ${({theme}) => theme.device.mobile} {
      width: 163px;
      height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 20px;
      line-height: 15px;
  }
`

const BannerMainText = styled.div`
  width: 550px;
  height: 60px;
  display:flex;
  align-items:center;
  font-weight:bold;
  font-size: 72px;
  line-height:86px;
  color: #ffffff;

  /* mobile */
  ${({theme}) => theme.device.mobile} {
      width: 292px;
      height: 39px;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 38px;
      line-height: 50px;
  }
`

export default HowBanner
