import React from 'react'
import styled from 'styled-components'

const HomeTitle:React.FC = () => {
    return (
        <TitleContainer>
            <TitleBox>
                <Title01>
                    라이트하우스는
                </Title01>
                <Title02>
                    "우리의 서비스와 제품을 통해 더 나은 세상을 만들자"
                </Title02>
                <Title03>
                    라는 포부를 가지고 시작하였습니다.
                </Title03>
                <Content>
                    고령 사회로 나아가는 전 세계, 그리고 특히 초고령사회로 나아가는 한국에서, 더 나은 세상을 만들기 위해 비단 주 IT 소비계층 뿐만 아니라, 노인 계층을 위한 서비스에 집중하고자 합니다. 우리는 초고령화 사회에서 발생 가능한 사회적, 경제적 문제를 우리의 IT서비스를 통해 더 큰 사회적 가치를 만들어냄으로써<br/>
                    해결하고자 합니다. 그리고 우리가 창출하는 가치가 시간의 흐름에 따라 모든 세대를 위한 가치로 발전되기를 희망합니다.
                </Content>
                {/* mobile component */}
                <MobileContent>
                    라이트하우스는<br/>
                    "우리의 서비스와 제품을<br/>
                    통해 더 나은 세상을 만들자"<br/>
                    라는 포부를 가지고<br/>
                    시작하였습니다.
                </MobileContent>
            </TitleBox>
        </TitleContainer>
    )
}

const TitleContainer = styled.section`
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 900px;
    background: #F6F4F2;;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 570px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 686px;
        background: #F6F4F2;
    }
`

const TitleBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Title01 = styled.h1`
    width:315px;
    height: 67px;
    font-size:46px;
    font-weight:bold;
    line-height:70px;
    margin-bottom: 5px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const Title02 = styled.h1`
    width:1073px;
    height:67px;
    color:#8C8579;
    font-size:46px;
    font-weight:bold;
    line-height:70px;
    margin-bottom: 5px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const Title03 = styled.h1`
    width:735px;
    height:67px;
    font-size:46px;
    font-weight:bold;
    line-height:70px;
    margin-bottom: 20px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const Content = styled.p`
    width:1202px;
    height:105px;
    font-size:20px;
    font-weight:500;
    line-height:35px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const MobileContent = styled.h1`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 28px;
        line-height: 50px;
    }
`

export default HomeTitle
