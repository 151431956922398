import React from 'react'
import styled from 'styled-components'
import {useNavigate} from 'react-router-dom';

// Images
import HWWImg from '../../../assets/img/desktop/home/unsplash_GWe0dlVD9e0.png';

const HomeHow:React.FC = () => {

    let navigate = useNavigate();

    function handleHowClick() {
        navigate("/how");
        window.scrollTo({top:0, behavior:'smooth'});
    }

    return (
        <HowContainer>
            <HowBox>
                <HowWeWorkImg src={HWWImg} alt="officeImage" />
                <HowContents>
                    <HowWeWorkTitle>
                        HOW WE WORK
                    </HowWeWorkTitle>
                    <HowWeWorkContent>
                        라이트하우스는 더 나은 세상을 만들기 위해 빠르게 변화하는<br />
                        세상속에서도 명확한 방향성을 가지고 앞으로 전진하고자 합니다.<br />
                        그러한 환경 속에서 사회적가치를 창출하며 앞으로 나아가기 위해 문제를<br />
                        직면할 때마다 늘 패기있게 도전하고 새로운 시도로 돌파해 나갈 것입니다.<br />
                        이를 위해 라이트하우스는 7가지의 핵심가치에 기반하여 일합니다.<br />
                        우리가 추구하는 핵심 가치에 대해 자세히 알아보세요.
                    </HowWeWorkContent>
                    <HowWeWorkContentM>
                        우리가 추구하는<br />
                        핵심 가치에 대해<br />
                        자세히 알아보세요.
                    </HowWeWorkContentM>
                    <HowWeWorkDetailBox onClick={handleHowClick}>
                        자세히보기
                    </HowWeWorkDetailBox>
                </HowContents>
            </HowBox>
        </HowContainer>
    )
}

const HowContainer = styled.section`
    width: 100%;
    height: 738px;
    display:flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3279px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 527px;
        position: absolute;
        top: 2719px;
    }
`

const HowBox = styled.div`
    display:flex;
    align-items: center;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 527px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`
const HowContents = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 527px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
`

const HowWeWorkImg = styled.img`
    width: 418px;
    height: 418px;
    background-color: #C4C4C4;
    border-radius: 20px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const HowWeWorkTitle = styled.h1`
    width: 541px;
    height: 60px;
    font-weight: bold;
    font-size: 72px;
    line-height: 60px;
    margin-bottom: 60px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 304px;
        height: 47px;
        font-size: 40px;
        line-height: 50px;
        margin: 100px 55px 60px 16px;
    }
`

const HowWeWorkContent = styled.p`
    width: 570px;
    height: 198px;
    font-weight:500;
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 40px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const HowWeWorkContentM = styled.p`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 300px;
        height: 102px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight:500;
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 60px;
        margin-left: 20px;
        margin-right: 55px;
    }
`

const HowWeWorkDetailBox = styled.div`
    width: 295px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #191F29;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #191F29;
    cursor: pointer;

    :hover {
        background: #8C8579;
        color: #ffffff;
        border-radius: 15px;
        border: none;
    }
    :active {
        background: #696358;
        color: #ffffff;
        border-radius: 15px;
        border: none;
    }

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 323px;
        height: 60px;
        margin-bottom: 98px;
        font-size: 16px;
        line-height: 24px;

        :hover {
        background: #8C8579;
        color: #ffffff;
        border-radius: 15px;
        border: none;
        }
        :active {
            background: #696358;
            color: #ffffff;
            border-radius: 15px;
            border: none;
        }
    }
`

export default HomeHow
