import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
// style
import GlobalStyles from './shared/GlobalStyles';
import theme from './shared/Theme';
// pages
import Home from './pages/Home/Home';
import Howwework from './pages/Howwework/Howwework';
import Contact from './pages/Contact/Contact';
// components
import Header from './components/Header/Header';
import Footer from './components/Footter/Footer';
const App:React.FC = () => {

  useEffect(() => {
    
    const onScroll = () => {
      if (128 < window.pageYOffset) {
          const HeaderBackgroundM = document.getElementById("header");
          HeaderBackgroundM!.style.backgroundColor = "#8C8579";
      } else {
          const HeaderBackgroundM = document.getElementById("header");
          HeaderBackgroundM!.style.backgroundColor = "transparent";
      }
  };
  
  window.addEventListener("scroll", onScroll);
  
  return () => {
      window.removeEventListener("scroll", onScroll);
  };
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <GlobalStyles />
          <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/how"  element={<Howwework />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
          </BrowserRouter>
      </ThemeProvider>
    
    </>
  );
}

export default App;
