import React from 'react'
import styled, { keyframes } from 'styled-components'

// Images
import MainImg from '../../../assets/img/desktop/banner/main_banner.png';
import MainImgM from '../../../assets/img/mobie/banner/mainbanner_mobile.png';

// Icons
import downArrow from '../../../assets/icons/Arrow - Down 2.svg';

const HomeBanner:React.FC = () => {
    return (
        <Container background={MainImg} height={900} >
            <TextBox>
                <BannerSubText data-aos="fade-up">라이트하우스</BannerSubText>
                <BannerMainText data-aos="fade-up">"우리의 서비스로 더 나은 세상을"</BannerMainText>
            </TextBox>
            <DownArrow src={downArrow} alt="moving down arrow icon" />
        </Container>
    )
}

interface IBannerStyleProps {
    height?: number;
    background?: string;
}

const Container = styled.div<IBannerStyleProps>`
    width: 100%;
    height:${props => `${props.height}`}px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image:${props => `url(${props.background})`};
    background-size: cover;
    background-position: center;
    position: absolute;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 686px;
        display: flex;
        flex-direction: column;
        background-image:url(${MainImgM});
        background-size: cover;
        background-position: center;
    }
`

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 375px;
        height: 686px;
        display: flex;
        justify-content: flex-start;
    }
`

const BannerSubText = styled.div`
    width: 249px;
    height: 45px;
    display:flex;
    align-items:center;
    position: relative;
    color: #ffffff;
    font-weight:500;
    font-size: 46px;
    line-height:30px;
    margin-bottom: 10px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const BannerMainText = styled.div`
    width: 930px;
    height: 86px;
    display:flex;
    align-items:center;
    position:  relative;
    color: #ffffff;
    font-weight:bold;
    font-size: 72px;
    line-height:86px;
    margin-bottom: 5px;
    

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 288px;
        height: 116px;
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;
        top:285px;
        left: 44px;
        font-size: 38px;
        line-height: 60px;
        margin: 0px;
    }
`

const movingAnimation = keyframes`
    from {
      left: 0px;
      top: -95px;
   }
   
   to {
      left: 0px;
      top: -89px;
   }
`

const DownArrow = styled.img`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 28px;
        height: 28px;
        display: flex;
        position: relative;
        animation: ${movingAnimation} 1s ease-in alternate infinite;
    }
`

export default HomeBanner