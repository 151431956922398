import React, {useEffect} from 'react'
import styled from 'styled-components'
import AOS from 'aos'
import '../../shared/aos.css'

// Components
import HowBanner from '../../components/HowWeWork/HowBanner/HowBanner';
import HowCard from '../../components/HowWeWork/HowCard/HowCard';
import HowWise from '../../components/HowWeWork/HowWise/HowWise';



const Howwework:React.FC = () => {

    useEffect(() => {
        AOS.init({
            duration : 1500
        });
    });

    return (
      <HowWeWorkContainer>

        {/* banner */}
        <HowBanner />

        {/* Cards  */}
        <HowCard />

        {/* Wise */}
        <HowWise />
      </HowWeWorkContainer>
    )
}

const HowWeWorkContainer = styled.section`
    width: 100%;
    height: 5933px;
    position:relative;
    
    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 5004px;
    }
`

export default Howwework;
