import React, { useEffect } from 'react'
import styled from 'styled-components'
import AOS from 'aos';
import "../../shared/aos.css"

// Components
import HomeBanner from '../../components/Home/Banner/HomeBanner';
import HomeTitle from '../../components/Home/Title/HomeTitle';
import HomeService from '../../components/Home/Service/HomeService';
import HomeWise from '../../components/Home/WiseSaying/HomeWise';
import HomeHow from '../../components/Home/HowWeWork/HomeHow';
import HomeNews from '../../components/Home/News/HomeNews';

const Home: React.FC = () => {

    useEffect(() => {
        const moveService = document.getElementById("service")
        if (moveService && window.location.hash === "#service") {
            moveService.scrollIntoView({ behavior: 'smooth' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.hash]);

    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);

    return (
        <HomeContainer>
            {/* Banner */}
            <HomeBanner />

            {/* Title */}
            <HomeTitle />

            {/* Service */}
            <HomeService />

            {/* Wise Saying aka.띵언 */}
            <HomeWise />

            {/* How We Work */}
            <HomeHow />

            {/* Light House News */}
            <HomeNews />

        </HomeContainer>
    )
}

const HomeContainer = styled.section`
    width: 100%;
    height: 5331px;
    
    /* mobile */
    ${({ theme }) => theme.device.mobile} {
        display: flex;
        width: 375px;
        height: 4342px;
    }
`

export default Home;