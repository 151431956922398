import React from 'react'
import styled from 'styled-components'

// Image
import Card01 from '../../../assets/img/desktop/hww/howwework_1.png';
import Card01M from '../../../assets/img/mobie/hhw/hhw1.png';
import Card02 from '../../../assets/img/desktop/hww/howwework_2.png';
import Card02M from '../../../assets/img/mobie/hhw/hhw2.png';
import Card03 from '../../../assets/img/desktop/hww/howwework_3.png';
import Card03M from '../../../assets/img/mobie/hhw/hhw3.png';
import Card04 from '../../../assets/img/desktop/hww/howwework_4.png';
import Card04M from '../../../assets/img/mobie/hhw/hhw4.png';
import Card05 from '../../../assets/img/desktop/hww/howwework_5.png';
import Card05M from '../../../assets/img/mobie/hhw/hhw5.png';
import Card06 from '../../../assets/img/desktop/hww/howwework_6.png';
import Card06M from '../../../assets/img/mobie/hhw/hhw6.png';
import Card07 from '../../../assets/img/desktop/hww/howwework_7.png';
import Card07M from '../../../assets/img/mobie/hhw/hhw7.png';

const HowCard:React.FC = () => {

    return (
        <Container>
            {/* gray box */}
            <Background />
            {/* first card */}
            <CardBox style={{marginTop: "180px", marginBottom:"180px"}}>
                <CardImage background={Card01} />
                <RightCardBox>
                    <Title 
                    width={433} 
                    height={55}
                    linHeight={30}
                    >Build Social Value
                    </Title>
                    <ContentsBox marginTop={193}>
                        <SubTitle 
                        width={183} 
                        height={42} 
                        top={1328} 
                        left={980}
                        >사회적 가치 창조 </SubTitle>
                        <Contents 
                            width={581} 
                            height={120} 
                            top={1390} 
                            left={980}>
                                우리의 서비스로 더 나은 세상을 만들자 라는것이<br/>
                                라이트하우스의 목표입니다. <br/>
                                라이트하우스의 구성원들은 가능성이 적은 환경에서도<br/>
                                사회적 가치를 창조하기 위하여 일합니다.			
                            </Contents>
                    </ContentsBox>
                </RightCardBox>
            </CardBox>

            {/* mobile */}
            <CardBoxM>
                <TitleM 
                width={257} 
                height={30}
                linHeight={30}
                marginTop={120}
                >Build Social Value
                </TitleM>
                <CardImageM background={Card01M} />
                <BackgroundM>
                    <SubTitleM 
                    width={131} 
                    height={42}
                    linHeight={42}
                    >사회적 가치 창조 </SubTitleM>
                    <ContentsM 
                    width={303} 
                    height={96}>
                        우리의 서비스로 더 나은 세상을 만들자 라는것이<br/>
                        라이트하우스의 목표입니다. <br/>
                        라이트하우스는 가능성이 적은 환경에서도<br/>
                        사회적 가치를 창조하기 위하여 일합니다.			
                    </ContentsM>
                </BackgroundM>
            </CardBoxM>


            {/* second card */}
            <CardBox style={{marginBottom:"180px"}}>
                <LeftCardBox style={{paddingRight:"41px"}}>
                    <Title 
                    width={355} 
                    height={110} 
                    top={null} 
                    left={null}
                    linHeight={35}
                    >Move Fast and<br/>Move Forward</Title>
                    <ContentsBox marginTop={108}>
                        <SubTitle 
                        width={159} 
                        height={42} 
                        top={1908} 
                        left={360}
                        >빠르게, 앞으로</SubTitle>
                        <Contents 
                        width={580} 
                        height={150} 
                        top={1970} 
                        left={360}
                        >
                            세상은 빠르게 변화해 왔고, 변하고 있으며, <br/>
                            심지어 그 변화의 속도도 점점 더 빨라지고 있습니다. <br/>
                            빠르게 변화하는 사회에서 사회적 가치를 창출하기 위해선<br/>
                            그에 맞는 움직임이 필요합니다. 라이트하우스의 구성원들은 더 좋은<br/>
                            서비스를 위해서 항상 전진하며, 그 속도를 늦추지 않습니다.
                        </Contents>
                    </ContentsBox>
                </LeftCardBox>
                <CardImage background={Card02} />
            </CardBox>

            {/* mobile */}
            <CardBoxM>
                <TitleM 
                width={224} 
                height={69}
                linHeight={35}
                marginTop={60}
                >Move Fast and<br/>Move Forward
                </TitleM>
                <CardImageM background={Card02M} />
                <BackgroundM>
                    <SubTitleM 
                    //width 110안맞음
                    width={114}
                    height={42}
                    linHeight={42}
                    >빠르게, 앞으로</SubTitleM>
                    <ContentsM 
                    width={303} 
                    height={119}>
                        빠르게 변화하는 사회에서 사회적 가치를<br/>
                        창출하기 위해선 그에 맞는 움직임이 필요합니다.<br/>
                        라이트하우스의 구성원들은 더 좋은 서비스를<br/>
                        위해서 항상 전진하며, 속도를 늦추지 않습니다.
                    </ContentsM>
                </BackgroundM>
            </CardBoxM>


            {/* third box */}
            <CardBox style={{marginBottom:"180px"}}>
                <CardImage background={Card03} />
                <RightCardBox>
                    <Title 
                    width={423} 
                    height={110} 
                    top={2300} 
                    left={980}
                    linHeight={35}
                    >Everything Takes<br/>Courage</Title>
                    <ContentsBox marginTop={138}>
                        <SubTitle 
                        width={128}
                        height={42} 
                        top={2548} 
                        left={980}
                        >용기와 함께</SubTitle>
                        <Contents 
                        width={580} 
                        height={120} 
                        top={2610} 
                        left={980}
                        >
                            모든 선택과 일에는 용기가 필요합니다. <br/>
                            우리의 용기와 함께라면, 해결하지 못할 문제란 존재하지 않습니다. <br/>
                            라이트하우스의 모든 구성원은 문제를 대면하고 극복해 나가기 위해<br/>
                            용기를 가지고 도전합니다.	
                        </Contents>
                    </ContentsBox>
                </RightCardBox>
            </CardBox>

            {/* mobile */}
            <CardBoxM>
                <TitleM 
                width={247} 
                height={78}
                linHeight={35}
                marginTop={60}
                >Everything Takes<br/>Courage
                </TitleM>
                <CardImageM background={Card03M} />
                <BackgroundM>
                    <SubTitleM 
                    width={92} 
                    height={42}
                    linHeight={42}
                    >용기와 함께</SubTitleM>
                    <ContentsM 
                    width={303} 
                    height={86}>
                        모든 선택과 일에는 용기가 필요합니다.<br/>
                        라이트하우스의 모든 구성원은 문제를 대면하고<br/>
                        극복해 나가기 위해 용기를 가지고 도전합니다.
                    </ContentsM>
                </BackgroundM>
            </CardBoxM>


            {/* fourth box */}
            <CardBox style={{marginBottom:"180px"}}>
                <LeftCardBox style={{paddingRight:"88px"}}>
                    <Title 
                    width={548} 
                    height={110} 
                    top={2910} 
                    left={360}
                    linHeight={35}
                    >We are All Different,<br/>Respect It</Title>
                    <ContentsBox marginTop={146}>
                        <SubTitle 
                        width={284} 
                        height={34} 
                        top={3166} 
                        left={360}
                        >다름을 인정하고 존중하기</SubTitle>
                        <Contents 
                        width={532} 
                        height={120} 
                        top={3220} 
                        left={360}
                        > 				
                            세상에는 78억명 이상의 사람들이, 매일 서로다른 78억개 이상의 <br/>
                            이야기를 써나가고 있습니다. 우리 모두는 서로 다릅니다. <br/>
                            라이트하우스의 구성원들은 서로의 다름을 인정하고 <br/>
                            존중하며 협업을 통해 진정한 새로운 가치를 창출합니다.
                        </Contents>
                    </ContentsBox>
                </LeftCardBox>
                <CardImage background={Card04} />
            </CardBox>

            {/* mobile */}
            <CardBoxM>
                <TitleM 
                width={285} 
                height={60}
                linHeight={35}
                marginTop={60}
                >We are All Different,<br/>Respect It
                </TitleM>
                <CardImageM background={Card04M} />
                <BackgroundM>
                    <SubTitleM 
                    width={200} 
                    height={25}
                    linHeight={25}
                    >다름을 인정하고 존중하기</SubTitleM>
                    <ContentsM 
                    width={303} 
                    height={96}>
                        우리 모두는 서로 다릅니다. <br/>
                        라이트하우스의 구성원들은 서로의 다름을<br/>  
                        인정하고 존중하며 협업을 통해 진정한<br/>
                        새로운 가치를 창출합니다.
                    </ContentsM>
                </BackgroundM>
            </CardBoxM>


            {/* fifth box */}
            <CardBox style={{marginBottom:"180px"}}>
                <CardImage background={Card05} />
                <RightCardBox>
                    <Title 
                    width={418} 
                    height={110} 
                    top={3520} 
                    left={980}
                    linHeight={35}
                    >User Focused, <br/> Always the First</Title>
                    <ContentsBox marginTop={138}>
                        <SubTitle 
                        width={207} 
                        height={42} 
                        top={3768} 
                        left={980}
                        >고객 중심을 첫째로</SubTitle>
                        <Contents 
                        width={580} 
                        height={120} 
                        top={3830} 
                        left={980}
                        > 
                            아무리 가치있는 일이거나 좋은 아이디어라도 고객이 없으면<br/>
                            의미가 없다는 것을 알고 있습니다. <br/>
                            라이트하우스의 구성원들은 항상 고객이 원하는 것, <br/>
                            고객의 니즈를 문제해결의 첫번째로 두고 업무를 수행합니다.	
                        </Contents>
                    </ContentsBox>
                </RightCardBox>
            </CardBox>

            {/* mobile */}
            <CardBoxM>
                <TitleM 
                width={244} 
                height={64}
                linHeight={35}
                marginTop={60}
                >User Focused, <br/> Always the First
                </TitleM>
                <CardImageM background={Card05M} />
                <BackgroundM>
                    <SubTitleM 
                    width={148} 
                    height={30}
                    linHeight={30}
                    >고객 중심을 첫째로</SubTitleM>
                    <ContentsM 
                    width={303} 
                    height={72}>
                        라이트하우스의 구성원들은 항상 고객이<br/>
                        원하는 것, 고객의 니즈를 문제 해결의 첫번째로<br/>
                        두고 업무를 수행합니다.
                    </ContentsM>
                </BackgroundM>
            </CardBoxM>


            {/* sixth box */}
            <CardBox style={{marginBottom:"180px"}}>
                <LeftCardBox style={{paddingRight:"40px"}}>
                    <Title 
                    width={401} 
                    height={110} 
                    top={4130} 
                    left={360}
                    linHeight={35}
                    >There Is Always<br/>a Better Way</Title>
                    <ContentsBox marginTop={78}>
                        <SubTitle 
                        width={176} 
                        height={42} 
                        top={4318} 
                        left={360}
                        >혁신적 문제해결</SubTitle>
                        <Contents 
                        width={580} 
                        height={180} 
                        top={4380} 
                        left={360}
                        > 
                            개인이건, 조직이건, 현실에 안주하는 순간부터 <br/>
                            이는 죽은 존재와 같습니다. 세상의 발전은 당연한 것이 아니라<br/>
                            누군가의 혁신적인 생각과 상상이상의 노력에 의해 이루어집니다.<br/>
                            우리는 모든 일에는 항상 더 나은 방법이 있다고 믿고 있습니다.<br/>
                            라이트하우스의 구성원들은 항상 더 나은 방법을 찾아<br/>
                            혁신적인 사회적 가치를 창출합니다.
                        </Contents>
                    </ContentsBox>
                </LeftCardBox>
                <CardImage background={Card06} />
            </CardBox>

            {/* mobile */}
            <CardBoxM>
                <TitleM 
                width={235} 
                height={69}
                linHeight={35}
                marginTop={60}
                >There Is Always<br/>a Better Way
                </TitleM>
                <CardImageM background={Card06M} />
                <BackgroundM>
                    <SubTitleM 
                    width={126} 
                    height={24}
                    linHeight={24}
                    >혁신적 문제해결</SubTitleM>
                    <ContentsM 
                    width={303} 
                    height={121}>
                        우리는 모든 일에는 항상 더 나은 방법이 있다고<br/>
                        믿고 있습니다.<br/>
                        라이트하우스의 구성원들은 항상 더 나은 방법을<br/>
                        찾아 혁신적인 사회적 가치를 창출합니다.
                    </ContentsM>
                </BackgroundM>
            </CardBoxM>


            {/* seventh box */}
            <CardBox>
                <CardImage background={Card07} />
                <RightCardBox>
                    <Title 
                    width={325} 
                    height={110} 
                    top={4740} 
                    left={980}
                    >Change Is<br/>a Good Thing</Title>
                    <ContentsBox marginTop={78}>
                        <SubTitle 
                        width={152} 
                        height={42} 
                        top={4928} 
                        left={980}
                        >변화를 가치로</SubTitle>
                        <Contents 
                        width={580} 
                        height={180} 
                        top={4990} 
                        left={980}
                        >
                            변화를 두려워하고 지금이 익숙하여 변화를 지향하지 않는다면,<br/>
                            시대의 잔여물이 될 수 밖에 없습니다.<br/>
                            변화는 늘 새로움을 가져오고 새로움은 또다시 가치를 창출합니다.<br/>
                            이렇게 세상은 더 나은 세상으로 발전합니다.<br/>
                            라이트하우스의 구성원들은 변화를 두려워하지 않고 늘 새로움을 찾아<br/>
                            가치를 창출합니다.
                        </Contents>
                    </ContentsBox>
                </RightCardBox>
            </CardBox>

            {/* mobile */}
            <CardBoxM>
                <TitleM 
                width={338} 
                height={30}
                linHeight={35}
                marginTop={60}
                >Change Is a Good Thing
                </TitleM>
                <CardImageM background={Card07M} />
                <BackgroundM>
                    <SubTitleM 
                    width={109} 
                    height={42}
                    linHeight={42}
                    >변화를 가치로</SubTitleM>
                    <ContentsM 
                    width={303} 
                    height={120}>
                        변화를 두려워하고 지금이 익숙하여 변화를<br/>
                        지향하지 않는다면, 시대의 잔여물이 될 수 밖에<br/>
                        없습니다. 라이트하우스의 구성원들은 변화를<br/>
                        두려워하지 않고 늘 새로움을 찾아 가치를<br/>
                        창출합니다.
                    </ContentsM>
                </BackgroundM>
            </CardBoxM>
        </Container>
    )
}

interface IHowWeWorkStyleProps {
    width?: number;
    height?: number;
    top?: number | null;
    left?: number | null;
    background?: string
    linHeight?: number;
    marginTop?: number;
    marginBottom?: number;
    paddingRight?: number;
  }

const Container = styled.section`
    width: 100%;
    height: 4270px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 3842px;
    }
`

const Background = styled.div`
    width: 100%;
    height: 512px;
    display: flex;
    position: absolute;
    top: 1308px;
    left: 0px;
    background: #F6F4F2;
    z-index:-1;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const BackgroundM = styled.div`
    background: #F6F4F2;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 343px;
        height: 221px;
        border-radius: 0px 0px 10px 10px / 0px 0px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }
`

const CardBox = styled.div<IHowWeWorkStyleProps>`
    height: 430px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${props => `${props.marginBottom}`};

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const CardBoxM = styled.div`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }
`

const RightCardBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`


const LeftCardBox = styled.div<IHowWeWorkStyleProps>`
    display: flex;
    flex-direction: column;
    padding-right: ${props => `${props.paddingRight}`};

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const CardImage = styled.div<IHowWeWorkStyleProps>`
    width: 580px;
    height: 430px;
    background: ${props => `url(${props.background})`};
    border-radius: 20px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`;

const CardImageM = styled.div<IHowWeWorkStyleProps>`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 343px;
        height: 144px;
        display: flex;
        border-radius: 20px 20px 0px 0px / 20px 20px 0px 0px;
        background:  ${props => `url(${props.background})`} ;
        background-size: cover;
        background-position: center;
    }
`;

const ContentsBox = styled.div<IHowWeWorkStyleProps>`
    display:flex;
    flex-direction: column;
    margin-top: ${props => `${props.marginTop}`}px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const Title = styled.h1<IHowWeWorkStyleProps>`
    width: ${props => `${props.width}`}px;
    height: ${props => `${props.height}`}px;
    font-weight:bold;
    font-size: 52px;
    line-height: 55px;
    display: flex;
    align-items: center;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const TitleM = styled.h1<IHowWeWorkStyleProps>`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: ${props => `${props.width}`}px;
        height: ${props => `${props.height}`}px;
        display: flex;
        font-weight:bold;
        font-size: 30px;
        line-height: ${props => `${props.linHeight}`}px;
        text-align: center;
        margin-top: ${props => `${props.marginTop}`}px;
        margin-bottom: 60px;
        color: #191F29;
    }
`

const SubTitle = styled.h4<IHowWeWorkStyleProps>`
    width: ${props => `${props.width}`}px;
    height: ${props => `${props.height}`}px;
    display: flex;
    align-items: center;
    font-weight:bold;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 20px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const SubTitleM = styled.h4<IHowWeWorkStyleProps>`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: ${props => `${props.width}`}px;
        height: ${props => `${props.height}`}px;
        display: flex;
        font-weight:bold;
        font-size: 20px;
        line-height: ${props => `${props.linHeight}`}px;
        margin: 20px 0px 10px 20px ;
        color: #191F29;
    }
`
const Contents = styled.p<IHowWeWorkStyleProps>`
    width: ${props => `${props.width}`}px;
    height: ${props => `${props.height}`}px;
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        display: none;
    }
`

const ContentsM = styled.p<IHowWeWorkStyleProps>`
    display: none;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: ${props => `${props.width}`}px;
        height: ${props => `${props.height}`}px;
        display: flex;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-left: 20px;
    }
`

export default HowCard
