// desktop = 1920 mobile = 375
const device:{mobile: string; desktop: string;} = {
    mobile: `@media screen and (min-width: 0px) and (max-width:1024px)`,
    desktop: `@media screen and (min-width: 1024px) and (max-width:100%)`,
  };

const theme = {
    device,
}

export default theme;