import React from 'react'
import styled from 'styled-components'

const HowWise:React.FC = () => {

    let mobileWidth:number | boolean = window.innerWidth < 376;

    return (
        <WiseSayingContainer>
            { mobileWidth 
            ?
            <>
                <WiseSayingTitle>
                    "If you want something<br/>
                    new, you have to stop<br/>
                    doing something old" 
                </WiseSayingTitle>
                <WiseSayingKor>
                    새로운것을 하고자 한다면,<br/>
                    오래된 것을 그만해야 합니다.
                </WiseSayingKor>
                <WiseSayingAuthor>
                    -Peter Drucker-
                </WiseSayingAuthor>
            </>
            :
            <WiseSayingBox>
                <WiseSayingTitle>
                    "If you want something
                    new, you have to stop doing something old" 
                </WiseSayingTitle>
                <WiseSayingKor>
                    새로운것을 하고자 한다면,<br /> 오래된 것을 그만해야 합니다.
                </WiseSayingKor>
                <WiseSayingAuthor>
                    -Peter Drucker-
                </WiseSayingAuthor>
            </WiseSayingBox>
            }
        </WiseSayingContainer>
    )
}

const WiseSayingContainer = styled.section`
    width: 100%;
    height: 583px;
    background: #EBE8E6;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 100%;
        height: 476px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0px;
        background: #ffffff;
    }
`


const WiseSayingBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 180px 0px;
`

const WiseSayingTitle = styled.h1`
    width: 872px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    line-height: 50px;
    color: #191F29;
    margin-bottom: 45px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 274px;
        height: 108px;
        font-size: 25px;
        line-height: 30px;
        margin-top: 160px;
        margin-bottom: 20px;
    }
`

const WiseSayingKor = styled.h4`
    width: 553px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #333D4C;
    margin-bottom: 30px;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 274px;
        height: 52px;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 10px;
    }
`

const WiseSayingAuthor = styled.h6`
    width: 143px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #333D4C;

    /* mobile */
    ${({theme}) => theme.device.mobile} {
        width: 137.5px;
        height: 26px;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 100px;
    }
`

export default HowWise
